<template>
  <div>
    <v-sheet class="pa-8">
      <p class="text-h5">
        Edit POS
      </p>
      <FormInput
        v-if="posData"
        method="edit"
        :loading="loading"
        :value="posData"
        :warehouse="warehouse"
        :status-list="statusList"
        @onSubmit="onSubmit"
        @onCancel="$router.go(-1)"></FormInput>
    </v-sheet>
  </div>
</template>

<script>
import PosManagementProvider from '@/resources/PosManagementProvider'
import { mapGetters } from 'vuex'
import FormInput from '../components/form-input.vue'

export default {
  components: {
    FormInput
  },
  data () {
    return {
      posManagementProvider: new PosManagementProvider(),
      posData: null,
      statusList: [
        'active',
        'inactive',
        'pm',
        'ma',
        'discharge'
      ],
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      mapWarehouse: 'Store/mapWarehouse'
    }),
    warehouse () {
      return this.mapWarehouse.filter((wh) => wh.code !== 'main')
    }
  },
  mounted () {
    this.fetchById(this.$route.params.id)
  },
  methods: {
    async fetchById (id) {
      try {
        const { data } = await this.posManagementProvider.getById(id)
        if (data) {
          this.posData = data
        }
      } catch (error) {
        console.error('errorFetchPOSById', error)
      }
    },
    async onSubmit (val) {
      this.loading = true
      const posData = { ...val }
      if (posData.note !== undefined) {
        delete posData.note
      }
      try {
        await this.posManagementProvider.updateById(this.posData.id, val)
        this.$router.push({ name: 'PreviewPosData', params: { id: this.posData.id } })
      } catch (error) {
        console.error('errorUpdatePOSById', error)
      }
      this.loading = false
    }
  }
}
</script>
